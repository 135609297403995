<template>
	<div style="height: 100%;">
		<main-table v-if="show_table">
			<el-form slot="header" :inline="true" :model="params" :size="theme.size">
				<el-form-item >
					<el-input v-model="params.keyword" placeholder="名称/备注说明关键词" clearable></el-input>
				</el-form-item>
				<el-form-item>
					<el-cascader style="width: 100%;" placeholder="请选择所属部门" v-model="params.departments" :options="tree_departments" :props="{expandTrigger: 'hover', value: 'unique_id', label: 'name', checkStrictly: true}" filterable clearable></el-cascader>
				</el-form-item>
				<el-form-item>
					<el-select v-model="params.employee_id" placeholder="请选择员工" filterable multiple collapse-tags>
						<el-option v-for="employee in employees" :key="employee.id" :label="employee.name+(employee.separated_at ? '(已离职)' : '')" :value="employee.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-select v-model="params.salary_field_id" placeholder="请选择字段" filterable multiple collapse-tags>
						<el-option v-for="field in fields" :key="field.id" :label="field.name" :value="field.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="getDetails({ ...params, page: 1})">查询</el-button>
					<el-button type="primary" @click="$refs['config'].open()">字段</el-button>
				</el-form-item>
			</el-form>
			<el-table slot="table" class="scroll-wrapper absolute" height="100%" :data="details" :size="theme.size">
				<el-table-column label="员工" min-width="90" :formatter="({employee}) => { return employee.name || '/'; }"></el-table-column>
				<el-table-column label="名称" prop="name" min-width="80"></el-table-column>
				<el-table-column label="字段" prop="salary_field.name" min-width="50"></el-table-column>
				<el-table-column label="配值" min-width="80" :formatter="({amount}) => { return +amount; }"></el-table-column>
				<el-table-column label="备注说明" prop="commit" min-width="100" show-overflow-tooltip></el-table-column>
				<el-table-column label="更新时间" prop="updated_at" min-width="150"></el-table-column>
				<el-table-column label="创建时间" prop="created_at" min-width="150"></el-table-column>
				<el-table-column label="操作" min-width="50">
					<el-link slot-scope="scope" type="primary" @click="delRow(scope.row)">删除</el-link>
				</el-table-column>
			</el-table>
			<el-pagination
				slot="footer"
				:page-sizes="$config.PAHE_SIZES"
				:page-size="params.perPage"
				:current-page="params.page"
				:total="params.total"
				@size-change="(v) => { getDetails({ ...params, page: 1, perPage: v }) }"
				@current-change="(v) => { getDetails({ ...params, page: v }) }"
				background>
			</el-pagination>
		</main-table>
		<main-table v-else tool>
			<el-result slot="table" icon="warning" title="警告提示" subTitle="暂不支持为员工设置薪酬配置，请前往薪酬列表初始化薪酬配置字段">
				<template slot="extra">
					<el-button type="primary" size="medium" @click="getDetails({...params, init: 1})">初始化配置</el-button>
				</template>
			</el-result>
		</main-table>
		<fields ref="config"></fields>
	</div>
</template>

<script>
	import { mapState } from 'vuex';
	import moment from 'moment';
	import mainTable from '@/components/main-table';
	import fields from './fields';

	export default {
		components: {
			mainTable,
			fields
		},
		computed: {
			...mapState(['theme', 'oa_departments']),
			tree_departments () {
				return this.$utils.buildTree(Object.values(this.oa_departments));
			}
		},
		filters: {
			format_at (t) {
				if (!t) return '无';
				return moment(t).format('Y-MM');
			}
		},
		methods: {
			delRow ({id, name}) {
				this.$confirm(name, '确定要删除吗？', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					this.handleDetails({action: 'delete', id});
				});
			},
			async handleDetails (params) {
				const res = await this.$http.get(this.$api.URI_SALARIES_CONFIGS, {params, headers: {loading: true}});
				const { code, msg } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.getDetails(this.params);
				this.$message.success(msg);
			},
			async getDetails (params, loading = false) {
				const res = await this.$http.get(this.$api.URI_SALARIES_CONFIGS, {params, headers: { loading }});
				const { code, msg, result } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.details = result.data;
				this.fields = result.fields;
				this.employees = result.employees;
				this.show_table = (result.fields && result.fields.length);
				this.params = {
					...params,
					total: result.total,
					perPage: parseInt(result.per_page)
				}
			}
		},
		data() {
			return {
				fields: [],
				details: [],
				employees: [],
				details_dialog: false,
				show_table: true,
				params: {
					perPage: 10
				}
			}
		},
		async created () {
			this.getDetails(this.params, true);
		}
	};
</script>